import Button from '@/components/general/Button';
import React from 'react';
import useUnAuth from '@/contexts/UnAuthContext';
import i18next from 'i18n';

const LoginOrSignUp = () => {
    const { showSignUpOrLogin } = useUnAuth();

    return (
        <div className="relative flex justify-center items-center">

            <Button
                light
                className="mx-[5px] whitespace-nowrap text-ellipsis !min-w-[110px] lg:!min-w-[140px]"
                size="md"
                onClick={() => showSignUpOrLogin('login')}
            >
                {i18next.t('signin', { ns: 'header' })}
            </Button>

            <Button
                className="mx-[5px] whitespace-nowrap text-ellipsis !min-w-[110px] lg:!min-w-[140px]"
                size="md"
                onClick={() => showSignUpOrLogin('signup')}
            >
                {i18next.t('signup', { ns: 'header' })}
            </Button>

        </div>
    );
};

export default LoginOrSignUp;
