import { UserAlert } from '@/api';
import { clearAlerts, getAlerts, readAlerts } from '@/queries/alert';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';
import uniqby from 'lodash.uniqby';
import useSocket from './useSocket';

export function useAlerts() {
    const queryClient = useQueryClient();

    const socket = useSocket();

    const { data: alerts, isFetching: loading, refetch } = useQuery({
        queryKey: ['getAlerts'],
        queryFn: getAlerts
    });

    useEffect(() => {
        if (socket) {
            const alertListener = (alert: UserAlert) => {
                queryClient.setQueryData(['getAlerts'], (current?: UserAlert[]) => {
                    return uniqby([alert, ...(current || [])], 'id');
                });
            };
            socket?.on('alert/created', alertListener);
            return () => {
                socket?.off('alert/created', alertListener);
            };
        }
        // manually refresh every 5 min
        const timeout = setInterval(() => {
            refetch();
        }, 1000 * 60 * 5);
        return () => {
            clearInterval(timeout);
        };
    }, [queryClient, socket, refetch]);

    const readAlertsMutation = useMutation({
        mutationFn: readAlerts,
        onMutate: () => {
            queryClient.setQueryData(['getAlerts'], (current?: UserAlert[]) => {
                return current?.map((alert) => {
                    return { ...alert, read: true };
                });
            });
        }
    });

    const clearAlertsMutation = useMutation({
        mutationFn: clearAlerts,
        onMutate: () => {
            queryClient.setQueryData(['getAlerts'], () => {
                return [];
            });
        }
    });

    const markRead = useCallback(() => {
        readAlertsMutation.mutateAsync();
    }, [readAlertsMutation]);

    const clear = useCallback(() => {
        clearAlertsMutation.mutateAsync();
    }, [clearAlertsMutation]);

    return {
        alerts,
        loading,
        markRead,
        clear
    };
}
