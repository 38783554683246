import { ResponsiveImage } from '@/components/general/ResponsiveImage';
import useAuth from '@/contexts/AuthContext';
import useSiteSettings, { IMenuItem } from '@/hooks/useSiteSettings';
import { Transition } from '@headlessui/react';
import clsx from 'clsx';
import i18next from 'i18n';
import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';

const LoggedInUser = ({ onOpen }: { onOpen?: () => void }) => {
    const router = useRouter();
    const pathname = usePathname();
    const [open, setOpen] = useState<boolean>(false);
    const ref = useDetectClickOutside({ onTriggered: () => setOpen(false) });
    const { userMenu } = useSiteSettings();
    const { user, logOut } = useAuth();

    useEffect(() => {
        if (open && onOpen) {
            onOpen();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const isActive = useCallback((href: string) => {
        return href === pathname && pathname.includes('settings') === href.includes('settings');
    }, [pathname]);

    if (!user) {
        return null;
    }

    return (
        <div className="relative" ref={ref}>
            <button
                type="button"
                className="flex justify-between items-center text-left "
                onClick={() => setOpen(!open)}
            >
                <div className="text-white bg-main rounded-full text-[18px] w-[42px] h-[42px] flex justify-center items-center">
                    {user.profile_image_url ? <ResponsiveImage alt="User Profile" className="rounded-full w-full h-full object-cover" src={user.profile_image_url} imageWidth={36} /> : user?.username?.substring(0, 1)}
                </div>
            </button>
            <Transition
                as={Fragment}
                show={open}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-0"
                enterTo="opacity-100 translate-y-1"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-1"
                leaveTo="opacity-0 translate-y-0"
            >
                <div className="md:absolute fixed right-0 md:top-11 top-[64px] shadow-default md:rounded-md p-0 bg-white md:w-[150px] w-[100vw] flex flex-col justify-center items-center overflow-hidden ">
                    <ul className="w-full">
                        {userMenu.items.map((item: IMenuItem, i: number) => (
                            <li
                                key={item.title}
                                className={clsx(
                                    'text-[16px] text-center text-grey2 md:py-2 hover:text-blue2 hover:bg-grey6 py-4 border-b-[1px] md:border-none capitalize',
                                    isActive(item.href) && 'text-main bg-grey6'
                                )}
                            >
                                <Link
                                    prefetch={false}
                                    onClick={(() => setOpen(false))}
                                    href={item.href}
                                    className="flex justify-center items-center"
                                >
                                    {item.icon}
                                    <span className="ml-2">{item.title}</span>
                                </Link>
                            </li>
                        ))}
                        <li
                            className={clsx(
                                'text-center text-grey2 hover:text-main hover:bg-grey6 cursor-pointer md:py-2 py-4 md:border-none'
                            )}
                        >
                            <button
                                className="flex justify-center items-center w-full"
                                onClick={() => {
                                    router.push('/');
                                    logOut();
                                }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-4 h-4"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                                    />
                                </svg>
                                <span className="ml-2 capitalize">{i18next.t('header:logout')}</span>
                            </button>
                        </li>
                    </ul>
                </div>
            </Transition>
        </div>

    );
};

export default LoggedInUser;
