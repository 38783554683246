import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import XIcon from 'public/assets/icons/x-white.svg';
import { AnimatePresence, motion } from 'framer-motion';
import clsx from 'clsx';
import { useGlobalData } from '@/providers/GlobalDataProvider';
import { useMobile } from '@/hooks/useMobile';
import { useSearchParams, useRouter, usePathname } from 'next/navigation';
import LoginForm from '../Layout/Header/Auth/LoginForm';
import SignUpForm from '../Layout/Header/Auth/SignUpForm';
import THDialogWrapper from './THDialogWrapper';

const LoginModal = ({ show, onHide, type }: { type: 'login' | 'signup' | undefined; show: boolean | undefined; onHide: () => void }) => {
    const [formType, setFormType] = useState<'login' | 'signup' | undefined>();
    const isMobile = useMobile();
    const router = useRouter();
    const pathname = usePathname();
    const params = useSearchParams();

    useEffect(() => {
        setFormType(type);
    }, [type]);

    useEffect(() => {
        if (isMobile && formType && show && !pathname.startsWith('/mobile')) {
            router.push(`/mobile/${formType}?${params.toString()}`);
        }
    }, [formType, isMobile, params, router, show, pathname]);

    const close = () => {
        onHide();
        if (params.get('signin') || params.get('signup')) {
            const newParams = new URLSearchParams(params as unknown as Record<string, string>);
            newParams.delete('signin');
            newParams.delete('signup');

            router.replace(`${pathname}?${newParams.toString()}`);
        }
    };

    const redirectIfNeeded = () => {
        if (params.get('redirect')) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            router.push(params.get('redirect')!);
        }
    };

    const { globalData } = useGlobalData();

    const cmsPicture = globalData?.data?.attributes?.loginPicture?.data?.attributes?.formats?.large?.url || globalData?.data?.attributes?.loginPicture?.data?.attributes?.formats?.medium?.url;

    return (
        <Transition.Root show={show && !isMobile} as={Fragment}>
            <THDialogWrapper
                as="div"
                className="relative z-10"
                onClose={() => {
                    close();
                }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 backdrop-blur-sm bg-black/20 transition-opacity " />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className={clsx('h-[60vh] relative max-h-[700px] flex flex-row justify-between transform overflow-hidden rounded-[24px] bg-white text-left shadow-xl transition-all w-full max-w-[900px]')}>
                                <button className="cursor-pointer" onClick={() => close()}><XIcon className="absolute right-[20px] top-[20px]" /></button>
                                <div className="grid grid-cols-[50%_50%] w-full">
                                    <div className="p-[60px] pt-[40px] h-[60vh] max-h-[700px] overflow-y-auto justify-center items-center">
                                        <AnimatePresence mode="wait" initial>
                                            {formType === 'login' && (
                                                <motion.div key="login" exit={{ opacity: 0 }} animate={{ opacity: 1 }}>
                                                    <LoginForm
                                                        onLoginSuccess={() => {
                                                            redirectIfNeeded();
                                                        }}
                                                        changeToSignup={() => {
                                                            setFormType('signup');
                                                        }} />
                                                </motion.div>
                                            )}
                                            {formType === 'signup' && (
                                                <motion.div key="signup" exit={{ opacity: 0 }} animate={{ opacity: 1 }}>
                                                    <SignUpForm
                                                        onSignUpSuccess={() => {
                                                            redirectIfNeeded();
                                                        }}
                                                        changeToLogin={() => setFormType('login')} />
                                                </motion.div>
                                            )}
                                        </AnimatePresence>

                                    </div>
                                    <div className="h-auto">
                                        <img src={cmsPicture || '/assets/temp-auth-image.png'} className="h-[101%] object-cover" alt="" />
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </THDialogWrapper>
        </Transition.Root>
    );
};

export default LoginModal;
