export type ILogo = {
    className?: string;
};

const Logo = (props: ILogo) => {
    const { className } = props;
    return (
        <svg
            width="1087"
            height="352"
            viewBox="0 0 1087 352"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <g clipPath="url(#clip0_528_19297)">
                <path
                    d="M417.34 121.42V231.19H396.1V121.42H373.43V102.32H439.65V121.42H417.34Z"
                    fill="#001F30"
                />
                <path
                    d="M489.801 231.19V175.5H467.131V231.19H445.891V102.32H467.131V156.58H489.801V102.32H511.041V231.19H489.801Z"
                    fill="#001F30"
                />
                <path
                    d="M545.662 155.51H570.652V173.54H545.662V212.09H581.002V231.19H524.422V102.32H579.572V121.07H545.662V155.51Z"
                    fill="#001F30"
                />
                <path
                    d="M664.886 231.19L650.246 177.64H642.576V231.19H621.156V102.32H654.536C679.526 102.32 684.526 118.92 684.526 140.87C684.526 162.82 679.886 169.43 668.996 174.6L685.776 231.18H664.896L664.886 231.19ZM650.966 160.51C660.606 160.51 663.456 156.05 663.456 140.34C663.456 124.63 660.606 120.17 650.966 120.17H642.576V160.51H650.966Z"
                    fill="#001F30"
                />
                <path
                    d="M716.459 155.51H741.449V173.54H716.459V212.09H751.799V231.19H695.219V102.32H750.379V121.07H716.459V155.51Z"
                    fill="#001F30"
                />
                <path
                    d="M777.857 102.32H803.027L825.517 231.18H804.987L801.237 204.41H779.647L775.897 231.18H755.367L777.857 102.32ZM782.497 186.03H798.387L790.537 132.84L782.507 186.03H782.497Z"
                    fill="#001F30"
                />
                <path
                    d="M853.888 212.27H886.188V231.19H832.648V102.32H853.888V212.27Z"
                    fill="#001F30"
                />
                <path
                    d="M915.459 155.51H940.449V173.54H915.459V212.09H950.799V231.19H894.219V102.32H949.369V121.07H915.459V155.51Z"
                    fill="#001F30"
                />
                <path
                    d="M995.238 158.9C1008.45 171.93 1018.62 183 1018.62 200.67C1018.62 220.66 1005.59 233.33 986.848 233.33C968.108 233.33 955.078 219.59 955.078 200.49V187.1H974.888V198.88C974.888 208.87 978.278 214.59 986.668 214.59C992.378 214.59 998.088 211.91 998.088 202.27C998.088 190.31 989.168 182.28 978.278 171.93C967.568 161.58 955.968 149.8 955.968 131.42C955.968 115 965.068 100.18 986.848 100.18C1005.59 100.18 1017.01 111.07 1017.01 135.16V143.37H997.198V136.23C997.198 123.2 993.628 118.56 986.848 118.56C979.708 118.56 976.498 123.91 976.498 130.52C976.498 140.51 983.998 147.83 995.238 158.9Z"
                    fill="#001F30"
                />
                <path
                    d="M1063.78 121.42V231.19H1042.54V121.42H1019.87V102.32H1086.09V121.42H1063.78Z"
                    fill="#001F30"
                />
                <path
                    d="M343.753 156.65C337.163 148.92 335.053 138.31 338.193 128.64C342.623 114.97 336.473 100.09 323.683 93.5597C314.643 88.9397 308.643 79.9497 307.843 69.8097C306.713 55.4797 295.353 44.0897 281.043 42.9597C270.923 42.1597 261.943 36.1497 257.333 27.0897C250.813 14.2797 235.963 8.11969 222.313 12.5597C212.653 15.6997 202.063 13.5897 194.343 6.98969C183.423 -2.34031 167.353 -2.34031 156.433 6.98969C148.713 13.5897 138.123 15.6997 128.463 12.5597C114.813 8.11969 99.9634 14.2797 93.4434 27.0897C88.8334 36.1497 79.8534 42.1597 69.7334 42.9597C55.4234 44.0897 44.0534 55.4697 42.9234 69.8097C42.1234 79.9497 36.1234 88.9397 27.0834 93.5597C14.2934 100.09 8.14344 114.96 12.5734 128.64C15.7134 138.31 13.6034 148.92 7.01344 156.65C-2.30656 167.58 -2.30656 183.68 7.01344 194.62C13.6034 202.35 15.7134 212.96 12.5734 222.63C8.14344 236.3 14.2934 251.18 27.0834 257.7C36.1334 262.32 42.1334 271.31 42.9234 281.45C44.0534 295.78 55.4134 307.16 69.7334 308.3C79.8534 309.1 88.8334 315.11 93.4434 324.17C99.9634 336.98 114.813 343.14 128.463 338.7C138.123 335.56 148.713 337.67 156.433 344.27C167.353 353.61 183.423 353.61 194.343 344.27C202.063 337.67 212.653 335.56 222.313 338.7C235.963 343.14 250.813 336.98 257.333 324.17C261.943 315.11 270.923 309.1 281.043 308.3C295.353 307.17 306.723 295.79 307.843 281.45C308.643 271.31 314.643 262.32 323.683 257.7C336.473 251.17 342.623 236.3 338.193 222.63C335.053 212.96 337.163 202.35 343.753 194.62C353.073 183.69 353.073 167.59 343.753 156.65Z"
                    fill="#001F30"
                />
                <path
                    d="M259.939 242.72C257.949 241.1 254.599 237.01 249.879 230.45L213.849 179.72C222.989 177.44 230.099 174.01 235.179 169.44C243.139 162.22 247.119 153.08 247.119 142.03C247.119 132.97 244.469 125.07 239.159 118.32C233.849 111.58 226.999 106.96 218.599 104.45C210.679 102.09 198.429 100.84 181.849 100.71H181.769L178.809 100.7H80.2891V141.26H84.4891C86.1791 130.21 90.2391 121.7 96.6491 115.73C101.219 111.46 108.549 109.32 118.639 109.32H129.469V224.93C129.469 232.44 129.059 237.09 128.249 238.86C127.149 241.37 125.629 243.13 123.719 244.16C122.699 244.76 121.509 245.2 120.229 245.57C117.579 246.17 113.909 246.48 109.179 246.48H108.249V250.57H186.719V246.48H185.879C180.919 246.48 177.119 246.15 174.439 245.49C173.419 245.17 172.459 244.8 171.629 244.32C169.159 242.88 167.499 241.21 166.659 239.29C165.809 237.37 165.389 232.59 165.389 224.92V184.9V170.72V109.34H176.929C188.789 109.34 197.389 112.07 202.739 117.52C208.079 122.97 210.749 130.84 210.749 142.26C210.749 150.15 209.299 156.54 206.379 161.44C203.469 166.34 199.469 169.73 194.389 171.61C189.919 173.26 183.399 174.17 174.929 174.37V182.28L223.229 250.58H270.419V246.49C266.439 246.27 262.939 245.02 259.919 242.73"
                    fill="#F1F2F2"
                />
            </g>
            <defs>
                <clipPath id="clip0_528_19297">
                    <rect width="1086.09" height="351.26" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
export default Logo;
