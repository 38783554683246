import useSiteSettings from '@/hooks/useSiteSettings';
import { NextSeo } from 'next-seo';
import React from 'react';

export type ISeoProps = {
    title: string | undefined;
    description: string | undefined;
};

const Seo = (props: ISeoProps) => {
    const { title, description } = props;
    const { defaultSeo } = useSiteSettings();
    return (
        <NextSeo
            title={title || defaultSeo.title}
            description={description || defaultSeo.description}
        />
    );
};

export default Seo;
