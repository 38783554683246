'use client';

import { Transition } from '@headlessui/react';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Button from '@/components/general/Button';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { useDebounce } from '@/hooks/useDebounce';
import { searchMutationQueryFn } from '@/queries/search';
import { useMutation } from '@tanstack/react-query';
import Skeleton from 'react-loading-skeleton';
import { Item } from '@/api';
import { useRouter } from 'next/navigation';
import ProductRow from '@/components/ecommerce/ProductRow';
import EmptyState from '@/components/general/EmptyState';
import i18next from 'i18n';
import { AnimatePresence, motion } from 'framer-motion';

const PredictiveSearch = ({ updateOpen }: { updateOpen?: (open: boolean) => void }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const ref = useDetectClickOutside({ onTriggered: () => setOpen(false) });
    const router = useRouter();
    const input = useRef<HTMLInputElement>(null);

    const debouncedValue = useDebounce<string>(search, 300);

    const searchMutation = useMutation({
        mutationFn: searchMutationQueryFn
    });

    const hasSearchValue = useMemo(() => search && search !== '' && search.length >= 3, [search]);

    useEffect(() => {
        if (!open) {
            setSearch('');
            setTimeout(() => {
                searchMutation.reset();
            }, 200); // wait for transiiton
        } else {
            input.current?.focus();
        }
        if (updateOpen) {
            updateOpen(open);
        }
    }, [open]);

    // Fetch API (optional)
    useEffect(() => {
        if (hasSearchValue) {
            searchMutation.mutate({ offset: 0, limit: 3, query: search });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedValue]);

    const seeMore = useCallback(() => {
        setOpen(false);
        router.push(`/search/${encodeURIComponent(search)}`);
    }, [router, search]);

    const hasResults = useMemo(() => {
        return searchMutation.data && searchMutation.data.results && searchMutation.data.results.length > 0 && !searchMutation.isLoading;
    }, [searchMutation]);

    return (
        <>
            <AnimatePresence>
                {open && <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 0.7 }}
                    exit={{ opacity: 0 }}
                    onClick={() => setOpen(false)}
                    className="fixed inset-0 backdrop-blur-[3px] bg-black z-[100] " />}
            </AnimatePresence>
            <div className={clsx('relative overflow-visible', open ? 'z-[103]' : 'z-[99]')}>
                <input
                    ref={input}
                    className={clsx(
                        'absolute right-[0] top-0 bg-grey z-10 rounded-full py-[12px] px-4 transition-all duration-200',
                        open ? 'md:w-[700px] tablet:w-[320px] opacity-100 max-w-[45vw]' : 'w-0 opacity-0'
                    )}
                    aria-autocomplete="none"
                    type="text"
                    name="q"
                    placeholder={i18next.t('header:search.placeholder')}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && hasSearchValue) {
                            seeMore();
                        }
                    }}
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                />
                <div
                    style={{ border: '1px solid #EFF0F0' }}
                    className={clsx(
                        'transition-all bg-grey rounded-full p-[11px] cursor-pointer text-main z-20 relative',
                        !open && 'hover:bg-main bg-white hover:text-grey'
                    )}
                    onClick={() => setOpen(!open)}
                >
                    {open ? (
                        <XMarkIcon
                            width="24"
                            height="24"
                        />
                    ) : (
                        <MagnifyingGlassIcon
                            width="24"
                            height="24"
                        />
                    )}
                </div>

            </div>
            <div
                className="fixed sm-tablet:w-full md:w-[900px] top-[90px] rounded-lg shadow-default px-3 bg-white left-[50%] md-tablet:translate-x-[-50%] translate-x-[-47%] right-0 w-[100vw] z-[101]">
                <Transition
                    show={open && debouncedValue !== '' && debouncedValue.length >= 3}
                    enter="transition-all duration-75"
                    enterFrom="opacity-0 -translate-y-8"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition-all duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 -translate-y-8"
                >
                    <div
                        ref={ref}
                    >
                        {searchMutation.isLoading ?
                            <div className="px-0 py-2">
                                <Skeleton height={177} count={3} />
                            </div> :
                            <div>
                                {hasResults && debouncedValue.length !== 0 ?
                                    searchMutation?.data?.results?.map((searchResult: Item) => <ProductRow key={searchResult.id} item={searchResult} onActionClicked={() => setOpen(false)} />) :
                                    EmptyState({
                                        title: i18next.t('header:search.empty-state-title', { 'search-text': debouncedValue }),
                                        subtitle: i18next.t('header:search.empty-state-subtitle'),
                                        actionText: i18next.t('general.marketplace'),
                                        pathRoute: '/marketplace',
                                        onLinkClick() {
                                            setOpen(false);
                                        },
                                    })}
                                {hasResults &&
                                    <div className="flex justify-center pb-5 pt-1">
                                        <Button onClick={seeMore}>{i18next.t('button-text.see-more')}</Button>
                                    </div>}
                            </div>
                        }

                    </div>
                </Transition>
            </div>
        </>
    );
};

export default PredictiveSearch;
