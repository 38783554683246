import { UserAlert } from '@/api';
import EmptyState from '@/components/general/EmptyState';
import LogoSmall from '@/components/general/LogoSmall';
import { ResponsiveImage } from '@/components/general/ResponsiveImage';
import useAuth from '@/contexts/AuthContext';
import { trackClick } from '@/helpers/Analytics';
import { useAlerts } from '@/hooks/api/useAlerts';
import { Transition } from '@headlessui/react';
import { BellIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import i18next from 'i18n';
import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';

const Alerts = ({ onOpen }: { onOpen?: () => void }) => {
    const [open, setOpen] = useState<boolean>(false);
    const { user } = useAuth();
    const ref = useDetectClickOutside({ onTriggered: () => setOpen(false) });
    const { alerts, markRead, clear } = useAlerts();
    const router = useRouter();
    const pathname = usePathname();

    const unreadAlertsCount = useMemo(() => (alerts || []).filter(({ read }) => !read).length, [alerts]);

    useEffect(() => {
        if (open && unreadAlertsCount > 0) {
            markRead();
        }
        if (open && onOpen) {
            onOpen();
        }
    }, [open, unreadAlertsCount, markRead, onOpen]);

    const renderAlert = useCallback((alert: UserAlert) => {
        let imageComponent = <LogoSmall className="[&>path]:fill-main" />;
        const messageComponent = <span className="font-[400] mr-[8px] inline-block text-[14px] self-start">{alert.message}</span>;
        let buttonComponent = null;
        let buttonTitle: string = i18next.t('button-text.view-item');

        switch (alert.type) {
            case UserAlert.TypeEnum.AUCTIONWON:
                buttonTitle = i18next.t('button-text.view-invoices');
                break;
            case UserAlert.TypeEnum.WELCOME:
                if (user?.onboarding_status !== 'done') {
                    buttonTitle = i18next.t('button-text.finish-onboarding');
                }
                break;
            case UserAlert.TypeEnum.ONBOARDINGREMINDER:
                if (user?.onboarding_status !== 'done') {
                    buttonTitle = i18next.t('button-text.finish-onboarding');
                }
                break;
            default:
                break;
        }
        let urlForBlock: string;

        if (alert.image_url) {
            imageComponent = <ResponsiveImage src={alert.image_url} imageWidth={48} alt="none" className="rounded-full w-[48px] h-[48px] bg-main min-w-[48px] object-cover" />;
        }
        if (alert.link) {
            urlForBlock = alert.link;
            buttonComponent = (
                <Link prefetch={false} href={alert.link} onClick={trackClick(`Alert:${buttonTitle}`, () => setOpen(false))} className="font-[400] text-[12px] underline cursor-pointer self-end">
                    {buttonTitle}
                </Link>
            );
        } else if ([UserAlert.TypeEnum.WELCOME, UserAlert.TypeEnum.ONBOARDINGREMINDER].includes(alert.type) && user?.onboarding_status !== 'done') {
            urlForBlock = `/onboarding?returnUrl=${pathname}`;
            buttonComponent = (
                <Link prefetch={false} href={urlForBlock} onClick={trackClick(`Alert:${buttonTitle}`, () => setOpen(false))} className="font-[400] text-[12px] underline cursor-pointer self-end">
                    {buttonTitle}
                </Link>
            );
        }
        return (
            <li
                key={alert.id}
                onClick={() => {
                    if (urlForBlock) {
                        trackClick(`Alert:${buttonTitle}`, () => setOpen(false))();
                        router.push(urlForBlock);
                        setOpen(false);
                    }
                }}
                className="flex cursor-pointer flex-row space-x-[16px] hover:bg-[#e8e8e8] transition-all px-[24px] py-[18px]">
                {imageComponent}
                <div className="w-full">
                    <div className="flex flex-col justify-center h-full items-center">
                        {messageComponent}
                        {buttonComponent}
                    </div>
                </div>
            </li>
        );
    }, [pathname, router, user?.onboarding_status]);

    return (
        <>

            <AnimatePresence>
                {open && <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 0.7 }}
                    exit={{ opacity: 0 }}
                    className="fixed inset-0 backdrop-blur-[3px] bg-black z-[100] " />}
            </AnimatePresence>
            <div className={clsx('relative', open ? 'z-[101]' : 'z-[9]')} ref={ref}>
                <button
                    type="button"
                    className="flex justify-between items-center text-left"
                    onClick={() => setOpen(!open)}
                >
                    <div className="text-main mr-[12px] bg-white realtive rounded-full text-[18px] h-[46px] flex justify-between items-center border-[1px] border-[#EAECF0] pl-[16px] pr-[16px] hover:bg-grey12">
                        {unreadAlertsCount > 0 ? (
                            <div className="absolute left-[26px] top-[12px] bg-grey12 p-[2px] rounded-full">
                                <div className="w-[7px] h-[7px] bg-red rounded-full animate-ping absolute opacity-75" />
                                <div className="w-[7px] h-[7px] bg-red rounded-full absolute" />
                            </div>
                        ) : null}
                        <BellIcon className="w-[20px] h-[20px] mr-[5px]" />{' '}{unreadAlertsCount > 0 ? unreadAlertsCount : 0}
                    </div>
                </button>
                <Transition
                    as={Fragment}
                    show={open}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-0"
                    enterTo="opacity-100 translate-y-1"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-1"
                    leaveTo="opacity-0 translate-y-0"
                >
                    <div className="font-secondary md:absolute fixed right-0 md:top-11 top-[64px] border-[1px]  border-[#EAECF0] shadow-default md:rounded-xl p-0 bg-white w-[100vw] md:w-[468px] flex flex-col justify-center items-center max-h-[80vh]">
                        <div className="flex flex-row justify-between items-center w-full border-b-[1px] pb-[12px] px-[24px] py-[12px]">
                            <h4 className="font-heading capitalize text-[28px]">alerts {unreadAlertsCount > 0 ? `(${unreadAlertsCount})` : null}</h4>
                            {alerts && alerts.length > 0 ? <button onClick={clear} className="text-[14px] font-[400] underline">{i18next.t('button-text.clear-all')}</button> : null}
                        </div>
                        <ul className="w-full overflow-scroll">
                            {alerts?.map((alert) => {
                                return renderAlert(alert);
                            })}
                        </ul>
                        {!alerts || alerts.length === 0 ? EmptyState({
                            title: i18next.t('header:notifications.empty-state-title'),
                            subtitle: i18next.t('header:notifications.empty-state-subtitle'),
                            noPadding: true,
                            actionText: i18next.t('general.marketplace'),
                            pathRoute: '/marketplace',
                            onLinkClick: () => { setOpen(false); }
                        }) : null}
                    </div>
                </Transition>

            </div>
        </>
    );
};

export default Alerts;
