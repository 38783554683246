import { trackClick } from '@/helpers/Analytics';
import clsx from 'clsx';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

export type INavLinkProps = {
    href: string;
    children: string;
    external?: boolean
};
const NavLink = (props: INavLinkProps) => {
    const { href, children, external } = props;
    const currentRoute = usePathname();

    return (
        <div>
            <Link
                onClick={trackClick()}
                href={href}
                target={external ? '_blank' : undefined}
                className={clsx('inline-block  text-[14px]  hover:text-blue12 px-4  tablet:px-1 tablet:text-[14px] capitalize font-[500]', currentRoute === href
                    ? 'text-blue2'
                    : 'text-grey7')}
            >
                {children}
            </Link>
        </div>
    );
};
export default NavLink;
